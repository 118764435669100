import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const theme = {
  colors: {
    primary: {
      50: '#EC2031',
      100: '#EC2031',
      200: '#EC2031',
      300: '#EC2031',
      400: '#EC2031',
      500: '#EC2031',
      600: '#EC2031',
      700: '#EC2031',
      800: '#EC2031',
      900: '#EC2031',
    },
  },
}

const breakpoints = createBreakpoints({
  sm: '480px',
  md: '720px',
  lg: '1024px',
  xl: '1280px',
})

export default extendTheme({ ...theme, breakpoints })
